import React from 'react'
import {Helmet} from "react-helmet";

export const HelmetTags = () => {
    const [appData, setAppData] = React.useState({
        name: "",
        favicon: null
    })

    React.useEffect(() => {
        ;(async () => {
            const client = window.location.hostname.split(".")[0]
            const clientInfos = await fetch(
                `https://ct-campaigns.civitimeapp.com/landing-pages/${client}/config.json?date=${new Date()}`
            ).then(r => r.text())
            const config = stringIsParsable(clientInfos) ? JSON.parse(clientInfos) : null
            setAppData({
                name: config?.APP_NAME || client,
                favicon: config?.FAVICON_URL || null
            })
        })()
    }, [])

    return <Helmet>
        <title>{appData?.name}</title>
        {
            appData?.favicon &&
            <link
                rel="icon"
                type="image/png"
                href={appData?.favicon}
            />
        }
    </Helmet>
}

const stringIsParsable = (string) => {
    try {
        JSON.parse(string)
        return true
    } catch (_e) {
        return false
    }
}