import React from 'react'
import styled from "@emotion/styled"

export const App = () => {
  const [landingUrl, setLandingUrl] = React.useState("")

  React.useEffect(() => {
    const client = window.location.hostname.split(".")[0]
    const landingUrl = `https://ct-campaigns.civitimeapp.com/landing-pages/${client}/index.html?date=${new Date()}`
    setLandingUrl(landingUrl)
  }, [])

  return <Landing src={landingUrl}/>
}

const Landing = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`